<template>
  <v-data-table :headers="table.headers" :items="items" hide-default-footer class="elevation-1">
    <template #item="row">
      <tr>
        <td>{{ row.item.asset.name }}</td>
        <td>{{ row.item.asset.assetCategory.name }}</td>
        <td>{{ row.item.asset.assetModel.name }}</td>
        <td>{{ row.item.borrowDate | formattedDate }}</td>
        <td>{{ row.item.dueDate | formattedDate }}</td>
        <td>{{ row.item.giveBackDate | formattedDate }}</td>
        <td>{{ row.item.quantity }}</td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
  export default {
    name: "TravelDebitTable",
    props: {
      items: Array
    },
    data: vm => ({
      table: {
        headers: [
          {
            text: vm.$t("hr.travel.name"),
            align: "left",
            sortable: false,
            value: "asset.name"
          },
          {
            text: vm.$t("hr.travel.category"),
            align: "left",
            sortable: false,
            value: "asset.name"
          },
          {
            text: vm.$t("hr.travel.model"),
            align: "left",
            sortable: false,
            value: "asset.name"
          },
          {
            text: vm.$t("hr.travel.borrow_date"),
            align: "left",
            sortable: false,
            value: "borrowDate"
          },
          {
            text: vm.$t("hr.travel.due_date"),
            align: "left",
            sortable: false,
            value: "dueDate"
          },
          {
            text: vm.$t("hr.travel.give_back_date"),
            align: "left",
            sortable: false,
            value: "giveBackDate"
          },
          {
            text: vm.$t("hr.travel.quantity"),
            align: "left",
            sortable: false,
            value: "quantity"
          }
        ]
      }
    })
  };
</script>

<style></style>
